@import './_all_vars.sass'

.container
  width: 100%

.page
  display: flex
  flex-direction: column
  width: 100%
  height: 100vh
  overflow: hidden
  background-color: white

  .recallLogo
    margin: 16px 0px
    height: 50px

  .columns
    display: flex
    flex-direction: row
    width: 100%
    height: 100vh

    .layoutContainer
      display: flex
      flex: 1
      flex-direction: column

    .mainPageWrapper
      position: relative
      display: flex
      flex-direction: column
      flex: 1
      overflow: hidden
      padding: 0 16px
      margin: 16px 0 16px 16px

@media screen and (max-width: $breakpoint--tablet + 1)
.page
  .columns
    .mainPageWrapper
      margin: 16px 0 16px 0px