@import './_all_vars.sass'

.menu
  background-color: $winwise-menu-background
  border-radius: 8px
  padding: 18px 0
  margin: 16px 0
  width: $winwise-menu-width
  position: relative
  display: flex
  flex-direction: column
  z-index: 100

  .recallLogo
    padding: 0 16px
    margin-bottom: 16px

  .menuItem
    position: relative
    flex: 0
    width: 100%
    color: $winwise-text-menu
    font-size: 16px
    padding: 12px
    margin: 4px 0

    &.active
      background-color: $winwise-menu-active

    img
      vertical-align: sub
      height: 20px
      padding-left: 4px

    i
      top: -2px
      color: black

    &.disabled
      opacity: 0.5

    &:hover
      cursor: pointer
      background-color: $winwise-text-menu-background-hover

    .appsMenuWrapper
      position: absolute
      display: flex
      flex-wrap: wrap
      gap: 8px
      top: -16px
      left: $winwise-menu-width + 4px
      background-color: white
      padding: 8px
      border: 1px solid #FAFAFA
      box-shadow: 0px 2px 4px 0px #0000001A
      box-sizing: border-box
      background-color: $winwise-menu-background

      .arrow
        position: absolute
        width: 20px
        height: 20px
        top: 30px
        left: -11px
        rotate: 45deg
        border-bottom: 10px solid $winwise-menu-background
        border-left: 10px solid $winwise-menu-background

      .appTile
        width: 120px
        border: 1px solid $winwise-text-lighter
        border-radius: 5px
        padding: 16px
        display: flex
        background-color: white

        img
          width: 100%

        &:hover
          background-color: $winwise-menu-background

  .menuVersion
    flex: 1
    display: flex
    align-self: center

    .text
      font-size: 12px
      align-self: flex-end
      text-align: center

  .terms
    flex: 0
    text-align: center
    margin-top: 8px

    a
      font-size: 12px

@media screen and (max-width: $breakpoint--tablet + 1)
  .menu
    border-radius: 0
    margin: 0
    width: $winwise-menu-width-small

    .menuItem
      .appsMenuWrapper
        left: $winwise-menu-width - 84px

@media screen and (max-width: $breakpoint--desktop-large + 1)
  .menu
    border-radius: 0
    margin: 0
