@import './_all_vars.sass'

.page
  display: flex
  flex: 1
  flex-direction: column
  overflow: auto
  margin-top: 16px

  .homeHeader
    flex-direction: column

  .content
    position: relative
    background-color: white
    padding: 2rem
    border-radius: 8px
    margin: 2rem 0
    overflow-wrap: anywhere

    ol
      li.report
        font-size: 24px
        margin-top: 40px

    ul
      li
        margin: 12px 0

    .multiItem
      margin-bottom: 24px
      padding-bottom: 24px
      border-bottom: 1px solid #ddd

    .end
      align-self: center
