@import './_all_vars.sass'

.page
  display: flex
  flex: 1
  flex-direction: column
  overflow: auto
  margin-top: 16px

  h1
    font-family: FiraGO
    font-size: 30px
    font-weight: 400
    color: $winwise-main-dark

  p
    font-size: 14px

    &.note
      color: $winwise-text-lighter
      font-style: italic

  a
    font-size: 14px

  .intro
    position: relative
    background-color: white
    padding: 0 2rem
    border-radius: 8px
    text-align: center

  .contents
    position: relative
    background-color: white
    padding: 0 2rem
    border-radius: 8px

  .submit
    display: flex
    flex-direction: row
    align-items: center

    .formError
      margin-left: 24px
      font-size: 12px
      color: $winwise-status-warning
      flex: 1

  .formElement
    flex-direction: column

    .formLabel
      font-weight: bold
      font-size: 14px
      line-height: 22px
      margin-bottom: 0

    .formWarning
      font-weight: normal
      font-size: 12px
      line-height: 20px
      color: $winwise-status-warning
      margin-top: 0

    .formInfo
      font-weight: normal
      font-size: 12px
      line-height: 20px
      color: $winwise-status-info
      margin-top: 0