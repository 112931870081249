$winwise-editor-tool-bar-background: #1b4747;

$primary--text--colour: #000;
$primary--text--colour--light: $aurecon--grey--800;
$primary--text--colour--lighter: $aurecon--grey--700;
$secondary--text--colour: #fff;
$secondary--text--colour--dark: $aurecon--grey--400;


$winwise-main-darker: #006358;
$winwise-main-dark: #00796B;
$winwise-main-light: #068576;
$winwise-status-warning: #da3b20;
$winwise-status-info: #2064da;
$winwise-dropdown-background: #fafafa;
$winwise-dropdown-option-background: #B2DFDB;

$winwise-text-menu: #373A36;
$winwise-text-menu-background-hover: #dee1dd;
$winwise-text-light: #666;
$winwise-text-lighter: #919191;
$winwise-text-disabled: #888;

$winwise-code-background: #eee;

$winwise-menu-background: #eee;
$winwise-menu-active: #ddd;
$winwise-separator: #ccc;

$winwise-primary: rgb(0, 121, 107);

$winwise-recall-chat: #85C446;
$winwise-gpt-chat: #F6D510;

$winwise-editor-tool-bar-background: #1b4747;

$winwise-menu-width: 175px;
$winwise-menu-width-small: 48px;
$winwise-ui-collapse: 750px;

$breakpoint--mobile: 576px;
$breakpoint--tablet: 768px;
$breakpoint--desktop: 992px;
$breakpoint--desktop-large: 1200px;