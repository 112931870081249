@import './_all_vars.sass'

@font-face
  font-family: FiraGo
  src: url(../assets/fonts/FiraGo.woff)

// ------ Loging page buttons START -------

.login
  button.is-primary
    &.is-white
      color: black
      border-color: white
      background-color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #555
        background-color: #555 !important


  button.is-secondary
    &.is-white
      color: white
      border-color: white
      background-color: transparent

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #999
        background-color: #999 !important

  button.is-text
    &.is-white
      color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: #999 !important
        border-color: transparent

// ------ Loging page buttons END -------

.pagination-wrapper
  .pagination-list
    button.pagination-chevron
      i.material-icons.chevron-icon
        color: $winwise-main-light

        &:hover
          background-color: $winwise-main-dark
          color: white

    button.pagination-item
      &.is-active
        color: white

      color: $winwise-main-light

      &:hover
        background-color: $winwise-main-dark
        color: white

.field-wrapper 
  > label 
    .input-field 
      .search-icon
        color: $winwise-main-light

.dropdown-wrapper 
  .dropdown-content 
    div.dropdown-selected
      background: $winwise-dropdown-background

      &.is-open
        background: $winwise-editor-tool-bar-background
        border: 1px solid $winwise-editor-tool-bar-background

        i
          color: $winwise-editor-tool-bar-background
          transform: rotate(180deg)
    
    .dropdown-options
      max-height: 202px
      overflow: auto

      .dropdown-item
        &:hover
          background: $winwise-dropdown-option-background

.winwise-icon-button
  button.button
    &.is-icon
      color: $winwise-main-light
      background: transparent

      &:disabled
        color: $winwise-main-light
        background: transparent

        &:hover
          color: $winwise-main-light

      &:focus,
      &:hover
        color: white

.toast-container 
  .top-right
    top: 84px
    right: 40px

@media screen and (min-width: 1200px)
  .report-list-container
    .container
      min-width: 1140px

.container
  margin: 0 auto

  &.is-fluid
    max-width: 100%

@media screen and (min-width: $breakpoint--mobile)
  .container
    margin: 0 auto
    width: 100%
    max-width: unset

@media screen and (min-width: $breakpoint--tablet)
  .container
    margin: 0 auto
    width: 100%
    max-width: unset

@media screen and (min-width: $breakpoint--desktop)
  .container
    width: 100%
    max-width: unset

@media screen and (min-width: $breakpoint--desktop-large)
  .container
    max-width: 1140px
